.rolesWrappper .MuiPopover-paper{
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06);
}

.rolesBox.roleDisable {
  opacity: 0.9;
}
.rolesBox.roleDisable h6 img {
  opacity: 0.6;
}


.rolesBoxWrap:nth-child(1 + 3*(n - 1)) .rolesBox{
  background-color: #F6F0EC !important;
}

.rolesBoxWrap:nth-child(3n+2) .rolesBox{
  background-color: #F2EBFC !important;
}

.rolesBoxWrap:nth-child(3n+3) .rolesBox{
  background-color: #D6EBE8 !important;
}

.listingWrapper {
  width: 100%;
  flex: 1 1 0;
  overflow: auto;
}

.listingItem {
  border-radius: 8px;
  padding: 5px 16px;
  margin: 8px 0 0 0;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
}

.listingItem:nth-child(odd) {
  border: 1px solid #ECECEC;
  background-color: #FFFFFF;
}

.listingItem:nth-child(even) {
  border: 1px solid #ECECEC;
  background-color: #ECECEC;
}

.rolesWrappper .MuiList-padding{
    padding-top: 16px;
    padding-bottom: 16px;
}

.rolesWrappper .MuiListItem-button:hover{
    background: #204B9C;
    color: #FFFFFF
}
