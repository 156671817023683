.upcharge-list-container {
	padding: 0 32px 0 48px;
	margin-bottom: 20px;
}

.upcharge-list-container .upcharge-list {
	margin: 0;
}

.upcharge-list-container .upcharge-list::-webkit-scrollbar {
	width: 5px;
}

.upcharge-list-container .upcharge-list::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px transparent;
	border-radius: 10px;
}

.upcharge-list-container .upcharge-list::-webkit-scrollbar-thumb {
	background: #D9D9D9;
	border-radius: 10px;
	height: 5px;
}

.upcharge-list-container .upcharge-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	padding-bottom: 0;
}

.upcharge-list-container .upcharge-top h3 {
	font-weight: 600;
}

.upcharge-list-container .list-opacity {
	opacity: 0.5;
}

.upcharge-list-container .upcharge-top button {
	outline: none;
	border: none;
	cursor: pointer;
	width: 150px;
	padding: 15px;
	border-radius: 8px;
	color: #fff;
	background-color: #204B9C;
	font-size: 16px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	line-height: 24px;
}


.upcharge-list-container .container-table {
	background-color: white;
	border-radius: 10px;
	border: 1px solid #ccc;
	margin: 20px 0;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.upcharge-list-container .header-ul {
	background-color: #204b9c;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.upcharge-list-container .header-list {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.upcharge-list-container .header-color {
	color: #fff;
}

.upcharge-list-container .border-list {
	border-radius: 10px;
	margin: 5px;
}

.upcharge-list-container .header-list .ascSort {
	cursor: pointer;
}

.upcharge-list-container .header-list .descSort {
	cursor: pointer;
	transform: scaleX(-1);
}

.upcharge-list-container .header-list-sec {
	display: flex;
	flex-basis: 75%;
	justify-content: space-between;
	padding: 20px;
	margin-left: 30px;
}

.upcharge-list-container .header-space {
	margin-left: 75px;
	flex-basis: 74%;
}

.upcharge-list-container .header-spacee {
	margin-left: 75px;
	margin-right: 65px;
}

.upcharge-list-container .option-section {
	display: flex;
	flex-basis: 90%;
	justify-content: space-between;
	border: 1px solid #ececec;
	border-radius: 10px;
	margin: 5px;
	padding: 15px;
	color: #1a1a1a;
	margin-left: 26px;
	background-color: #FFFFFF;
	font-size: 16px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	line-height: 22px;
}

.upcharge-list-container .header-section .MuiTableCell-root{
	border: none;
}
.upcharge-list-container .header-section {
	display: flex;
	flex-basis: 90%;
	justify-content: space-between;
	border-radius: 10px;
	margin: 5px;
	padding: 15px;
	margin-left: 75px;
	margin-right: 75px;
	color: #FFF;
	text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	font-size: 18px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	line-height: 22px;
}

.upcharge-list-container .option-item {
	flex-basis: 50%;
}

.upcharge-list-container .section-item {
	flex-basis: 20%;
}

.upcharge-list-container .section-item-inner {
	flex-basis: 20%;
}

.upcharge-list-container .section-item-inner svg {
	color: #204B9C;
}

.upcharge-list-container .section-item-inner-online {
	flex-basis: 18%;
}

.upcharge-list-container .section-item-inner-online svg {
	color: #204B9C;
}

.upcharge-list-container .drag-icon {
	margin-left: 20px;
}

.upcharge-list-container .icon-moreVert {
	margin-right: 26px;
	cursor: pointer;
}

.upcharge-list-container .position-relative {
	position: relative;
}

.upcharge-list-container .postion-container {
	z-index: 1;
	width: 140px;
	position: absolute;
	background: #ffffff;
	top: 23px;
	right: 32px;
	padding: 15px 0;
	border-radius: 8px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.upcharge-list-container .postion-container button {
	padding: 10px 30px;
	cursor: pointer;
	border: none;
	outline: none;
	background-color: #ffffff;
	display: block;
	width: 100%;
	text-align: left;
	font-size: 14px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 500;
	letter-spacing: -0.35px;
}

.upcharge-list-container .postion-container button:hover {
	background-color: #204b9c;
	color: #ffffff;
}

.upcharge-list-container .postion-container span {
	padding: 10px 15px;
	width: 100%;
	text-align: left;
	font-size: 14px;
}

.upcharge-list-container .draggable-item {
	display: flex;
	align-items: center;
	background-color: #f5f5f5;
	border: 1px solid #ddd;
	border-radius: 4px;
	margin-bottom: 5px;
	padding: 10px;
	justify-content: space-between;
}

.upcharge-list-container .pagination {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 10px;
	font-size: 0.85rem;
}

.upcharge-list-container .pagination button {
	margin: 0 5px;
	padding: 5px 10px;
	font-size: 16px;
	border-radius: 4px;
	background-color: transparent;
	cursor: pointer;
	outline: none;
	border: none;
}

.upcharge-list-container .pagination-label {
	color: #1a1a1a;
}

.font-montserrat {
	font-family: 'Montserrat', sans-serif;
}

.upcharge-view-container .view-upcharge {
	display: flex;
	align-items: center;
}
.upcharge-view-container .view-upcharge img {
	width:24px;
	height: 24px;
	margin-right: 16px;
}