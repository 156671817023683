
.main-dialog-container .MuiDialog-paper {
	border-radius: 24px !important;
	/* width: 600px; */
}

.deactivate-modal .MuiDialog-paper {
	border-radius: 24px !important;
}
.dialog-main {
	width: 471px;
	text-align: center;
	margin: 40px;
}
@media only screen and (max-width: 1024px) {
	.dialog-main {
		width: 520px;
		text-align: center;
		margin: 40px;
	}
};
.display-none {
	display: none !important;
}
.dialog-main h2 {
	color: #1a1a1a !important;
	font-size: 24px !important;
	font-weight: 600 !important;
  font-family: 'Montserrat', sans-serif !important;
}
.dialog-para-wrap {
  padding: 0 !important;
}
.dialog-main p {
	font-size: 18px !important;
	color:#000000 !important;
  margin-bottom: 32px !important;
  font-family: 'Montserrat', sans-serif !important;
}
.icon-section {
	margin-bottom: 25px;
}
.icon-section svg {
	font-size: 64px;
}
.icon-section.close svg {
	color: #dc2626;
}
.icon-section.error svg {
	color: #204b9c;
}
* {
	font-family: "Montserrat", sans-serif;
}

.buttons button {
	border: none;
	outline: none;
	padding: 10px 20px;
	border-radius: 8px;
	font-size: 16px;
	cursor: pointer;
	text-transform: none;
	font-weight:600
}

.buttons .btn-cancel {
	color: #64748b;
	background: #f1f5f9;
	margin-right: 20px;
	width:130px;
}
.width-extend{
	width: 80%;
}
.buttons .btn-cancel:hover {
	color: #64748b !important;
	background: #f1f5f9 !important;
}

.buttons .btn-confirm {
	color: #ffffff;
	background: #204b9c;
}
.buttons .btn-confirm:hover {
	color: #ffffff !important;
	background: #204b9c !important;
}
