.permissionCard .MuiFormControlLabel-label {
    color: #4D4D4D
}

.permissionCard .MuiCheckbox-root {
    color:#ECECEC;
    border-radius: 6px;
}

.permissionCard .Mui-checked + .MuiFormControlLabel-label {
    color: #204B9C;
}

.permissionCard .MuiFormControlLabel-root {
    margin-bottom: 10px;
}