.adminDialog .MuiFormControlLabel-label {
    color: #4D4D4D
}

.adminDialog .MuiCheckbox-root {
    color:#ECECEC;
    border-radius: 6px;
}

.adminDialog .Mui-checked + .MuiFormControlLabel-label {
    color: #204B9C;
}
 
.adminDialog .MuiDialogContent-root { 
    overflow-y: unset;
    margin-left: 38px;
}
.adminDialog .MuiDialog-paperWidthSm {
    max-width: 760px;
    border-radius: 24px;
}