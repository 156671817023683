/** @format */

.preference-list-container {
	padding: 0 32px 0 48px;
	position: relative;
}

.preference-list-container .preference-list {
	margin: 0;
}

.preference-list-container .preference-list::-webkit-scrollbar {
	width: 5px;
}

.preference-list-container .preference-list::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px transparent;
	border-radius: 10px;
}

.preference-list-container .preference-list::-webkit-scrollbar-thumb {
	background: #d9d9d9;
	border-radius: 10px;
	height: 5px;
}

.preference-list-container .preference-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 40px 0;
}

.preference-list-container .preference-top h3 {
	font-weight: 600;
}

.preference-list-container .list-opacity {
	opacity: 0.5;
}

.preference-list-container .preference-top button {
	outline: none;
	border: none;
	cursor: pointer;
	width: 165px;
	padding: 15px;
	border-radius: 8px;
	color: #fff;
	background-color: #204b9c;
	font-weight: 600;
	font-size: 16px;
}

.preference-list-container .container-table {
	background-color: white;
	border-radius: 10px;
	border: 1px solid #ccc;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	font-weight: 600;
}

.preference-list-container .header-ul {
	background-color: #204b9c;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.preference-list-container .header-list {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.preference-list-container .header-color {
	color: #fff;
}

.preference-list-container .border-list {
	border-radius: 10px;
	margin: 5px;
}

.preference-list-container .header-list-sec {
	display: flex;
	flex-basis: 92%;
	justify-content: space-between;
	padding: 15px 20px;
	margin-left: 30px;
	align-items: center;
}
.preference-list-container .header-list-spectication {
	display: flex;
	flex-basis: 76%;
	justify-content: space-between;
	padding: 15px 20px;
	margin-left: 30px;
	align-items: center;
}

.preference-list-container .header-space {
	margin-left: 75px;
	flex-basis: 77%;
}
.preference-list-container .drag-drop-header{
	flex-basis: 92% !important;
}

.header-space .active-para {
	margin-right: 48px;
}

.preference-list-container .option-section {
	display: flex;
	flex-basis: 92%;
	justify-content: space-between;
	border: 1px solid #ececec;
	border-radius: 10px;
	margin: 5px;
	padding: 15px;
	color: #1a1a1a;
	margin-left: 26px;
	align-items: center;
}

.option-sec-preference {
	justify-content: unset !important;
}

.option-sec-preference .option-item {
	flex-basis: 38% !important;
}

.option-sec-preference .section-item {
	flex-basis: 14% !important;
}

.preference-list-container .option-item {
	flex-basis: 20%;
	font-weight: 600;
	color: #1a1a1a;
	margin-left: 5px;
}
.preference-list-container .option-section-item {
	flex-basis: 16%;
}

.preference-list-container .section-item {
	flex-basis: 20%;
}

.preference-list-container .section-item-inner {
	flex-basis: 18%;
}

.preference-list-container .section-item-inner svg {
	color: #204b9c;
}

.preference-list-container .section-item-inner-online {
	flex-basis: 22%;
}

.preference-list-container .section-item-inner-online svg {
	color: #204b9c;
}

.preference-list-container .drag-icon {
	margin-left: 20px;
}

.preference-list-container .image-container {
	flex-basis: 25.5%;
	text-align: right;
}

.preference-list-container .image-container img {
	width: 25px;
	height: 25px;
}

.preference-list-container .icon-moreVert {
	margin-right: 26px;
	cursor: pointer;
}

.preference-list-container .position-relative {
	position: relative;
}

.preference-list-container .postion-container {
	z-index: 1;
	width: 140px;
	position: absolute;
	background: #ffffff;
	top: 23px;
	right: 32px;
	padding: 15px 0;
	border-radius: 8px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.preference-list-container .postion-container button {
	padding: 10px 30px;
	cursor: pointer;
	border: none;
	outline: none;
	background-color: #ffffff;
	display: block;
	width: 100%;
	text-align: left;
	font-size: 14px;
}

.preference-list-container .postion-container button:hover {
	background-color: #204b9c;
	color: #ffffff;
}

.preference-list-container .view-detail {
	display: flex;
	margin: 30px 45px;
}

.preference-list-container .view-detail-inner {
	flex-basis: 50%;
	margin-bottom: 20px;
}

.preference-list-container .first-preference {
	margin-bottom: 30px;
}

.preference-list-container .view-detail-inner p {
	font-size: 18px;
	margin-bottom: 8px;
	color: #4d4d4d;
}

.preference-list-container .view-detail-inner span {
	font-size: 15px;
	color: #1a1a1a;
}

.preference-list-container .cancel-button button {
	border: none;
	outline: none;
	padding: 15px;
	border-radius: 8px;
	width: 130px;
	font-size: 16px;
	cursor: pointer;
	color: #64748b;
	background: #f1f5f9;
	font-weight: 600;
}

.preference-list-container .table {
	list-style-type: none;
	padding: 0;
}

.preference-list-container .table-header {
	display: flex;
	align-items: center;
	background-color: #f5f5f5;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.preference-list-container .table-label {
	flex: 1;
	font-weight: bold;
	padding: 10px;
}

.preference-list-container .table-body {
	padding: 0;
}

.preference-list-container .draggable-item {
	display: flex;
	align-items: center;
	background-color: #f5f5f5;
	border: 1px solid #ddd;
	border-radius: 4px;
	margin-bottom: 5px;
	padding: 10px;
	justify-content: space-between;
}

.preference-list-container .pagination {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 10px;
	font-size: 0.85rem;
}

.preference-list-container .pagination button {
	margin: 0 5px;
	padding: 5px 10px;
	font-size: 16px;
	border-radius: 4px;
	background-color: transparent;
	cursor: pointer;
	outline: none;
	border: none;
}

.preference-list-container .pagination-label {
	color: #1a1a1a;
	font-size: 1rem;
	font-weight: 500;
}

.preference-list-container .view-preference {
	display: flex;
	align-items: center;
}

.preference-list-container .view-preference img {
	width: 24px;
	height: 24px;
	margin-right: 20px;
}

.specification-header {
	justify-content: unset !important;
}

.specification-header .first-option {
	flex-basis: 48%;
}

.option-first-item {
	flex-basis: 21%;
}

.item-inner-span {
	margin-right: 20px;
}

.empty-item-list {
	text-align: center;
	margin: 30px 0;
}

.preference-list-container .back-button {
	width: 130px;
}

.dots-section {
	display: flex;
	margin-top: 25px;
}

.dots-section-inner {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	margin-right: 20px;
}

.dots-section .dots-green {
	background-color: #059669;
}

.dots-section .dots-inactive {
	border: 1px solid #4d4d4d;
}

.button-add-spec {
	width: 180px !important;
}

.inner-span {
	margin-right: 10px;
}

.section-option-item {
	flex-basis: 72%;
}

.section-arrow {
	display: flex;
	align-items: center;
	flex-basis: 25%;
}
.section-arrow p {
	cursor: pointer;
}

.filter-button-group {
	display: flex;
}

.filter-icon-section {
	padding: 10px 20px;
	border: 1px solid #204b9c;
	display: inline-block;
	border-radius: 8px;
	cursor: pointer;
	margin-right: 20px;
}

.filter-icon-section svg {
	color: #4d4d4d;
}

.preference-list-container .filterButton {
	padding: 16px;
	border-radius: 15px;
	border: solid 1px rgba(32, 75, 156, 1);
	cursor: pointer;
	margin-right: 24px;
}

.filtergroup {
	display: flex;
}

.section-option-group {
	margin-left: 24px;
	margin-right: 24px;
	height: 200px;
	overflow-x: hidden;
	width: 192px;
}
.section-option-group::-webkit-scrollbar {
	width: 5px;
}

.section-option-group::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px transparent;
	border-radius: 10px;
}

.section-option-group::-webkit-scrollbar-thumb {
	background: #d9d9d9;
	border-radius: 10px;
	height: 5px;
}
.section-option-group .label-option {
	display: flex;
    margin-bottom: 20px;
    color: #4D4D4D;
	cursor: pointer;
}
.section-option-group .label-option img{
margin-right: 12px;
}
.section-option-group .checkbox {
	display: flex;
    margin-bottom: 20px;
    color: #4D4D4D;
	cursor: pointer;
	align-items: center;
}
