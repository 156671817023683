.code{
    width: 3.444vw;
        height: 3.444vw;
        background: #FFFFFF;
        border: 0.069vw solid #204B9C;
        border-radius: 0.833vw;
        font-family: Montserrat,serif;
        font-weight: 600;
        font-size: 1.667vw;
        margin:0.694vw;
        outline: none;
        text-align: center;
}
.code::-webkit-outer-spin-button,
.code::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.code:valid {
  border-color: #9861c2;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.25);
}
.errorMsg {
  text-align: center;
  color:#FF0000;
  font-size:12px
}