/** @format */

* {
	margin: 0;
	padding: 0;
	font-family: "Montserrat", sans-serif;
}

.product-container {
	padding: 0 32px 0 48px;
}

.product-container .header-title {
	font-weight: 600;
	margin: 40px 0;
}

.product-container .add-product-container {
	display: flex;
	margin: 20px 0;
	padding: 25px;
	border-radius: 12px;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.product-container .right-section {
	flex-basis: 50%;
}

.product-container .left-section {
	flex-basis: 50%;
}

.product-container .input-field {
	margin-top: 7px;
	width: 85%;
	outline: none;
	border-radius: 8px;
	padding: 16px;
	border: 1px solid #ececec;
	text-transform: capitalize;
}

.product-container .heading-text {
	color: #334155;
	font-weight: 500;
	font-size: 18px;
}

.product-container .custom-dropdown {
	margin: 30px 0;
	position: relative;
	display: inline-block;
	width: 50%;
}

.product-container .dropdown-header {
	display: flex;
	align-items: center;
	padding: 10px 20;
	border: 1px solid #ccc;
	border-radius: 8px;
	cursor: pointer;
	margin-top: 10px;
}

.product-container .dropdown-header span {
	flex-grow: 1;
	margin-right: 10px;
	display: flex;
	align-items: center;
}

.product-container .dropdown-options {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1;
	width: 100%;
	margin-top: 5px;
	padding: 0;
	list-style-type: none;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
	height: 200px;
	overflow: auto;
}

.product-container .dropdown-options::-webkit-scrollbar {
	width: 5px;
}

.product-container .dropdown-options::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px transparent;
	border-radius: 10px;
}

.product-container .dropdown-options::-webkit-scrollbar-thumb {
	background: #d9d9d9;
	border-radius: 10px;
	height: 5px;
}

.product-container .dropdown-options li {
	padding: 10px;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.product-container .image-change-option {
	width: 24px;
	height: 24px;
	margin: 0 20px;
}

.product-container .dropdown-options li:hover {
	background-color: #f2f2f2;
}

.fa-chevron-up,
.fa-chevron-down {
	margin-left: 10px;
}

.fa-chevron-up:before {
	content: "\f077";
}

.fa-chevron-down:before {
	content: "\f078";
}

.product-container .checkbox-container input[type="checkbox"] {
	transform: scale(1.5);
	margin-right: 22px;
	cursor: pointer;
}

.product-container .checkbox-container .check-input-label {
	display: flex;
	align-items: center;
	margin-right: 10px;
	cursor: pointer;
}

.product-container .checkbox-container .color-change {
	color: #204b9c;
}

.group-btn {
	margin-left: 41px;
	margin-top: 40px;
}

.group-btn button {
	border: none;
	outline: none;
	padding: 15px;
	border-radius: 8px;
	width: 130px;
	font-size: 16px;
	cursor: pointer;
	font-weight: 600;
}

.group-btn .cancel {
	color: #64748b;
	background: #f1f5f9;
	margin-right: 20px;
}

.group-btn .add {
	color: #ffffff;
	background: #204b9c;
}

.product-container .error {
	border-color: red;
}

.product-container .error-message {
	color: red;
	font-size: 14px;
	margin-top: 4px;
}

.product-container .dropdown-header.error {
	border-color: red;
}
